<template>
    <div>
                
        <v-card flat>
            <v-card-title>
                <span class="text-h5">{{ title }}</span>
            </v-card-title>
            <v-card-text>

                <v-container v-if="lectorReady">
                    <v-data-table
                        :headers="headersCustom || headers"
                        :items="itemsModified"
                        :items-per-page=-1
                        class="elevation-1 custom-font"
                        loading-text="Cargando... Espere por favor"
                        
                    >
                        <template v-slot:item.quantityAssigned="{ item }">
                            <v-chip :color="item.quantityAssigned === 0 ? 'red' : 
                                item.quantityAssigned === item.quantity ? 'green' : 'warning'" text-color="white">{{ item.quantityAssigned }}</v-chip>
                        </template>
                    </v-data-table>

                </v-container>

                <div style="text-align: center; top: 20px;" v-else>
                        <vue-qrcode :value="dataUrl" margin="1" scale="5"/>
                        <br>
                        <p>Escanea el codigo QR para sincronizar</p>
                </div>

            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="restartQrInit" v-if="maxAtemptsQr || !retryAtempts">
                    Reintentar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deliveiesOk" v-if="okDeliveries">
                    Continuar
                </v-btn>
                <v-btn color="blue darken-1" text @click="cancelStock">
                    Cerrar
                </v-btn>

            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import UserProfile from "../data";
import VueQrcode from 'vue-qrcode'
import conf from '../conf/ruta-base'
import { createBarcodeProccess, getBarcodeById } from '../dataApi'
import { assingRequerimientos } from '../proccess'
export default {
    components: { VueQrcode },
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        solicitud: {
            type: String,
            required: true
        }
    },
    name: 'dataTableBasicSync',
    data: () => ({
        user: UserProfile.getUserData(),
        atempts: 0,
        dataUrl: null,
        code: new Date().getTime().toString(),
        lectorReady: false,
        retryAtempts: true,
        id: null,
        itemsModified: [],
        headersCustom: [
            { text: 'Nombre', value: 'employeeName' },
            { text: 'Cargo', value: 'employee.position' },
            { text: 'Producto', value: 'product.name' },
            { text: 'Detalles', value: 'product.description' },
            { text: 'Cantidad', value: 'quantity' },
            { text: 'Confirmados', value: 'quantityAssigned' },
        ],
        quantityProductsRead: null
    }),
    methods: {
        onDataUrlChange(dataUrl) {
            this.dataUrl = dataUrl
        },

        cancelStock(){
            this.retryAtempts = false
            this.$emit("closeModal")
        },

        async activateLector(){
            const barcode = await createBarcodeProccess({
                code: this.code,
                ref: this.solicitud,
                items: []
            })
            console.log("activateLectorId", barcode)
            if(barcode) this.id = barcode.id
            this.validateLectorConnection()
        },

        async validateLectorConnection(){
            setTimeout(async () => {
                this.atempts++
                const response = await getBarcodeById(this.id)
                if(response?.active) {
                    this.lectorReady = true
                    if(this.quantityProductsRead !== response.items.length) {
                        this.quantityProductsRead = response.items.length
                        this.atempts = 0
                    }
                    const itemsModified = assingRequerimientos(this.id, this.items, response.items);
                    this.itemsModified = []
                    this.itemsModified.push(...itemsModified)
                }
                if(this.retryAtempts) this.validateLectorConnection();
            }, 5000)
        },

        deliveiesOk() {
            this.retryAtempts = false
            this.$emit('okProccessDeliveries');
        },

        restartQrInit() {
            this.atempts = 0
            this.retryAtempts = true
            this.validateLectorConnection()
        },
        async handleKeyPress(event) {
            console.log("🚀 ~ file: DataTableBasicSync.vue:144 ~ event:", event.key, this.user)
            if (event.key === 'F2' && (this.user.level === 'sys' || this.user.level === 'admin')) {
                this.deliveiesOk();
            }
        }
    },
    mounted() {
        const url = `${conf.hostname}/#/barcode?entrega=${this.code}`;
        console.log("QR info",this.solicitud, {url}, this.items)
        this.dataUrl = url
        this.activateLector();
        window.addEventListener('keydown', this.handleKeyPress);
    },
    beforeDestroy() {
        // Es importante remover el event listener cuando el componente se destruye
        window.removeEventListener('keydown', this.handleKeyPress);
    },
    beforeUnmount() {
        this.retryAtempts = false
    },
    computed: {
        okDeliveries(){
            const pending = this.itemsModified.filter(item => item.quantityAssigned < item.quantity)
            console.log({pending})
            return pending.length === 0 && this.lectorReady
        },

        maxAtemptsQr(){
            if(this.atempts > 3) {
                this.retryAtempts = false
                return true
            }
            return false
        },

        maxAtempsReader(){
            if(this.lectorReady && this.atempts > 3) {
                this.retryAtempts = false
                return true
            }
            return false
        }

    }
}
</script>